import React from 'react';
import { useParams } from 'react-router-dom';

const LookerDashboard = () => {
  const {locationId} = useParams()
  const iframeSrc = `https://lookerstudio.google.com/embed/reporting/f50c56bf-cbc0-4178-ae41-cc6699ed8866/page/68PBE?params=%7B%22df11%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${locationId}%22%7D`;
  return (
    <div className='card' style={{ height: '100vh' }}>
      <iframe
        src={iframeSrc}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        title="RD - Appt Analysis Report"
      ></iframe>
    </div>
  );
};

export default LookerDashboard;
